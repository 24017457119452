import { ChangeDetectorRef, Component, EventEmitter, HostListener, Injector, Input, OnInit, Output } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/app-component-base';
import { SelectItem } from '../vitrine/filtro/select-item.interface';
@Component({
  selector: 'input-select-text',
  templateUrl: './input-select-text.component.html',
  styleUrls: ['./input-select-text.component.scss'],
  animations: [appModuleAnimation()]
})
export class InputSelectTextComponent extends AppComponentBase implements OnInit {

  @Input() valores: SelectItem[];
  @Input() inputLivre: boolean = false;
  @Input() placeholder: string = '';
  @Output() selectedOptionChange = new EventEmitter<SelectItem>();
  @Input() selectValor: SelectItem = this.getSelectItem();
  @Input() disabled: boolean = false;
  filteredValores: SelectItem[] = [];
  isDropdownVisible: boolean = false;

  constructor(injector: Injector, private cdr: ChangeDetectorRef) {
    super(injector);
  }

  ngOnInit(): void {
    this.filteredValores = this.valores;
  }

  onInput(event: any): void {
    const inputValue = this.normalizeString(event.target.value);

    if (inputValue) {
      this.filteredValores = this.valores.filter(valor =>
        this.normalizeString(valor.item_text).includes(inputValue)
      );

      this.isDropdownVisible = this.filteredValores.length > 0;

      const valorEncontrado = this.valores.find(
        valor => this.normalizeString(valor.item_text) === inputValue
      );
      if (!valorEncontrado) {
        this.selectValor.item_id = null;
        if (this.inputLivre) {
          this.selectedOptionChange.emit(this.selectValor);
        }
      }
    } else {
      this.selectValor = this.getSelectItem();
      this.isDropdownVisible = this.inputLivre && this.filteredValores.length > 0;
      this.selectedOptionChange.emit(this.selectValor);
    }

    this.cdr.detectChanges();
  }


  normalizeString(value: string): string {
    return value
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }


  selectValorFromList(valor: SelectItem): void {
    this.selectValor = { ...valor };
    this.isDropdownVisible = false;
    this.selectedOptionChange.emit(this.selectValor);
    this.cdr.detectChanges();
  }

  toggleDropdown(): void {
    this.isDropdownVisible = !this.isDropdownVisible;
    if (!this.selectValor.item_id) {
      this.selectValor = this.getSelectItem();
      this.filteredValores = this.valores;
    }
    this.cdr.detectChanges();
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (!this.isDropdownVisible) return;

    const clickedInside = this.elementRef.nativeElement.contains(event.target);

    if (!clickedInside) {
      const valorEncontrado = this.valores.find(
        (e) => e.item_text.trim().toLowerCase() === this.selectValor.item_text.trim().toLowerCase()
      );

      if (valorEncontrado) {
        this.selectValor = valorEncontrado;
      } else if (!this.selectValor.item_id && !this.inputLivre) {
        this.selectValor = this.getSelectItem();
        this.filteredValores = this.valores;
      }

      this.isDropdownVisible = false;

      if (this.inputLivre || valorEncontrado) {
        this.selectedOptionChange.emit(this.selectValor);
      }

      this.cdr.detectChanges();
    }
  }

  onEnterKey(): void {
    if (this.inputLivre && this.selectValor.item_text) {
      this.selectedOptionChange.emit(this.selectValor);
      this.isDropdownVisible = false;
    }
    this.cdr.detectChanges();
  }

  getSelectItem(item_id: any = null, item_text: string = null): SelectItem {
    return { item_id, item_text };
  }
}
