<!-- isCadastro == true -->
<div *ngIf="isCadastro" class="row mb-4">
    <div class="form-group cadastro-style col-12">
        <label class="form-label" for="cepEndereco">CEP</label>
        <input type="text" class="form-control" id="cepEndereco" placeholder="00000-000" required
            [(ngModel)]="endereco.cep" name="cepEnderecoCadastro" minlength="8" mask="00000-000" (blur)="onCepBlur()"
            (input)="onFieldChangeEndereco()" #cepEnderecoModel="ngModel" />
    </div>

    <div class="form-group cadastro-style col-3">
        <label class="form-label required" for="estado">UF</label>
        <input-select-text *ngIf="selectedEstadoIsCadastro" [valores]="estados" maxlength="128"
        (selectedOptionChange)="onSelectOrTypeEstadoCadastro($event)"
        [selectValor]="selectedEstadoIsCadastro" [placeholder]="'Selecione um Estado'">
        </input-select-text>
    </div>

    <div class="form-group cadastro-style col-9">
        <label class="form-label required" for="cidade">Cidade</label>
        <input-select-text *ngIf="selectedCidadeIsCadastro" [valores]="cidades" maxlength="128"
            [placeholder]="desabilitarCidade ? 'Primeiro escolha um Estado' : 'Selecione a cidade'"
            [disabled]="desabilitarCidade" [selectValor]="selectedCidadeIsCadastro"
            (selectedOptionChange)="onSelectOrTypeCidadeCadastro($event)">
        </input-select-text>
    </div>
</div>

<div class="row mb-4" *ngIf="isCadastro">
    <div class="form-group cadastro-style col-12">
        <label class="form-label required" for="logradouro">Rua</label>
        <input type="text" class="form-control" id="logradouro" placeholder="Rua" required
            [(ngModel)]="endereco.logradouro" name="logradouro" maxlength="256" (input)="onFieldChangeEndereco()"
            #logradouroModel="ngModel" />
    </div>
</div>

<div class="row mb-4" *ngIf="isCadastro">
    <div class="form-group cadastro-style col-md-3">
        <label class="form-label" for="numero">Número</label>
        <input type="text" class="form-control" id="numero" placeholder="000" onlyAlphanumeric required [(ngModel)]="endereco.numero"
            name="numero" maxlength="20" (input)="onFieldChangeEndereco()" #numeroModel="ngModel" />
    </div>

    <div class="form-group cadastro-style col-md-9" *ngIf="isCadastro">
        <label class="form-label" for="complemento">Complemento (opcional)</label>
        <input type="text" class="form-control" id="complemento" placeholder="Complemento"
            [(ngModel)]="endereco.complemento" name="complemento" maxlength="20" (input)="onFieldChangeEndereco()"
            #complementoModel="ngModel" />
    </div>
</div>

<div class="row mb-4" *ngIf="isCadastro">
    <div class="form-group cadastro-style col-12">
        <label class="form-label required" for="bairro">Bairro</label>
        <input type="text" class="form-control" id="bairro" placeholder="Bairro" required [(ngModel)]="endereco.bairro"
            name="bairro" maxlength="50" (input)="onFieldChangeEndereco()" #bairroModel="ngModel" />
    </div>
</div>

<!--------- isCadastro == false ---------->

<div *ngIf="!isCadastro" [ngClass]="{'justify-content-between': !perfil, 'justify-content-start': perfil}" class="row mb-5">
    <div class="form-group col-md-5 mr-5">
        <label class="form-label" for="cepEndereco">Cep</label>
        <div>
            <input required placeholder="00.000-000" id="cepEndereco" type="text" class="form-control"
                name="cepEndereco" (blur)="onCepBlur()" minlength="8" mask="00000-000" [(ngModel)]="endereco.cep"
                (input)="onFieldChangeEndereco()"
                #cepEnderecoModel="ngModel" #cepEnderecoEl />
        </div>
    </div>

    <div class="form-group col-md-5">
        <label class="form-label required" for="estado">UF</label>
        <div>
            <ng-multiselect-dropdown [placeholder]="'Selecione um Estado'" [settings]="dropdownSettings" maxlength="128"
                [data]="dropdownEstadosList" [(ngModel)]="selectedEstados" (onSelect)="onEstadoSelected($event)"
                (onDeSelect)="onEstadoDeSelected()">
            </ng-multiselect-dropdown>
        </div>
    </div>
</div>

<div *ngIf="!isCadastro" [ngClass]="{'justify-content-between': !perfil, 'justify-content-start': perfil}" class="row mb-5">
    <div class="form-group col-md-5 mr-5">
        <label class="form-label required" for="cidade">Cidade</label>
        <div>
            <ng-multiselect-dropdown [disabled]="desabilitarCidade" [placeholder]="'Selecione uma cidade'" maxlength="128"
                [settings]="dropdownSettings" [data]="dropdownCidadesList" [(ngModel)]="selectedCidades"
                (onSelect)="onCidadeSelected($event)" (onDeSelect)="onCidadeDeSelected()">
            </ng-multiselect-dropdown>
        </div>
    </div>

    <div class="form-group col-md-5">
        <label class="form-label required" for="logradouro">Logradouro</label>
        <div>
            <input required type="text" class="form-control" name="logradouro" id="logradouro" maxlength="256"
                [(ngModel)]="endereco.logradouro" (input)="onFieldChangeEndereco()" #logradouroModel="ngModel" #logradouroEl />
            <abp-validation-summary [control]="logradouroModel" [controlEl]="logradouroEl"></abp-validation-summary>
        </div>
    </div>
</div>

<div *ngIf="!isCadastro" [ngClass]="{'justify-content-between': !perfil, 'justify-content-start': perfil}" class="row mb-5">
    <div class="form-group col-md-5 mr-5">
        <label class="form-label required" for="bairro">Bairro</label>
        <div>
            <input required type="text" class="form-control" name="bairro" id="bairro" maxlength="50"
                [(ngModel)]="endereco.bairro" (input)="onFieldChangeEndereco()" #bairroModel="ngModel" #bairroEl />
            <abp-validation-summary [control]="bairroModel" [controlEl]="bairroEl"></abp-validation-summary>
        </div>
    </div>

    <div class="form-group col-md-5">
        <label class="form-label" for="complemento">Complemento</label>
        <div>
            <input type="text" class="form-control" name="complemento" id="complemento" maxlength="20"
                [(ngModel)]="endereco.complemento" (input)="onFieldChangeEndereco()" #complementoModel="ngModel" #complementoEl />
            <abp-validation-summary [control]="complementoModel" [controlEl]="complementoEl"></abp-validation-summary>
        </div>
    </div>
</div>

<div *ngIf="!isCadastro" class="row">
    <div class="form-group col-md-3">
        <label class="form-label" for="name">
            Número
        </label>
        <div>
            <input type="text" class="form-control" onlyAlphanumeric name="numero" id="numero" maxlength="20"
                [(ngModel)]="endereco.numero" (input)="onFieldChangeEndereco()" #numeroModel="ngModel" #numeroEl />
            <abp-validation-summary [control]="numeroModel" [controlEl]="numeroEl">
            </abp-validation-summary>
        </div>
    </div>
</div>
