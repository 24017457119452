import { Component, HostListener, Injector, Input, OnInit } from "@angular/core";
import { AppComponentBase } from "@shared/app-component-base";
import localePtBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { CandidatoDto, VagaDto } from "@shared/service-proxies/service-proxies";
import { Router } from "@angular/router";
import { CandidatoService } from "candidato/service/candidato.service";
import { ConfirmationDialogComponent } from "./detalhes/candidatar/candidatar-vaga.component";
import { MatDialog } from "@angular/material/dialog";
import { ModelosTrabalho } from 'shared/constant/modelos-trabalho.model';

@Component({
  selector: "card-candidato-vaga",
  templateUrl: "./card-candidato-vaga.component.html",
  styleUrls: ["./card-candidato-vaga.component.scss"],
})
export class CardCandidatoVagaComponent extends AppComponentBase implements OnInit {
  @Input() vaga: VagaDto;
  @Input() isInscrito: boolean;
  pcdTitle: string = this.l("Vaga para pessoas com deficiência.");
  candidato: CandidatoDto = new CandidatoDto();
  modeloHomeOffice: ModelosTrabalho = ModelosTrabalho.homeoffice;
  modeloPresencial: ModelosTrabalho = ModelosTrabalho.presencial;
  modeloHibrido: ModelosTrabalho = ModelosTrabalho.hibrido;
  descricaoTruncada = '';


  constructor(
    injector: Injector,
    private _router: Router,
    private candidatoService: CandidatoService,
    private dialog: MatDialog,
  ) {
    super(injector);
    registerLocaleData(localePtBr);
  }
  ngOnInit(): void {
    this.truncarDescricao();
  }

  @HostListener('window:resize', [])
  onResize() {
    this.truncarDescricao();
  }

  truncarDescricao() {
    const maxChars = this.getMaxCharacters();
    this.descricaoTruncada = this.truncateHtml(this.vaga.descricao, maxChars);
  }

  getMaxCharacters(): number {
    const screenWidth = window.innerWidth;
    if (screenWidth > 3000) {
      return 300;
    }
    else if (screenWidth > 2100) {
      return 250;
    }
    else if (screenWidth > 1900) {
      return 250;
    }
    else if (screenWidth > 1700) {
      return 200;
    }
    else if (screenWidth > 1200) {
      return 160;
    }
    else if (screenWidth > 1400) {
      return 170;
    }
    else if (screenWidth > 1150) {
      return 150;
    }
    else if (screenWidth > 1000) {
      return 100;
    }
    else if (screenWidth > 768) {
      return 150;
    }
    else if (screenWidth > 700) {
      return 130;
    }
    else if (screenWidth > 628) {
      return 220;
    }
    else if (screenWidth > 530) {
      return 190;
    }
    else if (screenWidth > 430) {
      return 160;
    } else {
      return 130;
    }
  }

  truncateHtml(html: string, maxChars: number): string {
    let charCount = 0;
    const div = document.createElement('div');
    div.innerHTML = html;

    const walkNodes = (node: Node): string => {
      if (charCount >= maxChars) return '';
      if (node.nodeType === Node.TEXT_NODE) {
        const text = node.textContent || '';
        const remainingChars = maxChars - charCount;
        if (text.length > remainingChars) {
          charCount = maxChars;
          return text.slice(0, remainingChars) + '...';
        } else {
          charCount += text.length;
          return text;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as HTMLElement;
        let result = `<${element.tagName.toLowerCase()}`;

        Array.from(element.attributes).forEach(attr => {
          result += ` ${attr.name}="${attr.value}"`;
        });

        result += '>';
        Array.from(node.childNodes).forEach(child => {
          result += walkNodes(child);
        });

        if (!['img', 'br', 'hr'].includes(element.tagName.toLowerCase())) {
          result += `</${element.tagName.toLowerCase()}>`;
        }
        return result;
      }
      return '';
    };

    return walkNodes(div);
  }

  detalhes() {
    this.candidatoService.setInscrito(this.isInscrito);
    this._router.navigate(['candidato', 'detalhes-vaga', this.vaga.id]);
  }

  candidatar() {
    this.candidato = this.candidatoService.getCandidato();
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        vaga: this.vaga,
        candidato: this.candidato,
      },
    });
  }
}
